import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { withQuery } from '../utils/withQuery';
import Layout from '../components/layout';
import Nav from '../components/nav';
import Heading from '../components/heading';

const Columns = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
  @media screen and (min-width: 769px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  @media screen and (min-width: 769px) {
    margin-right: 4rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  flex-grow: 1;
  margin-top: 2rem;
  
  @media screen and (min-width: 769px) {
    margin-right: -4rem;
    margin-top: 0;
  }
  
  > div {
    border: 2px solid #212121;
  }
  
  p {
    position: absolute;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 0;
  }
`;

const StyledNav = styled(Nav)`
  ul {
    padding: 0;
    list-style: none;
    margin: 0 -1rem;
  }
  
  li {
    display: block;
    margin: 1rem;
  }

  a {
    color: inherit;
    font-size: 1.3125rem;
    line-height: 2rem;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Index = ({ image: { fluid, title } }) => {
  return (
    <Layout pageTitle="Home" withFooter={false}>
      <Columns>
        <ContentContainer>
          <Heading css={{ marginBottom: '1.5rem' }}>Linda Black</Heading>
          <StyledNav/>
        </ContentContainer>
        <ImageContainer>
          <div>
            <Img fluid={fluid} alt={title}/>
          </div>
          <p css={{ fontStyle: 'italic' }}>{title}</p>
        </ImageContainer>
      </Columns>
    </Layout>
  );
};

export const query = graphql`
  query Homepage {
    contentfulPage(contentful_id: {eq: "4ID3t2aMWbjOfWuQuaiu3n"}) {
      image {
        title
        medium
        image {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const mapDataToProps = ({ contentfulPage: { image } }) => {
  const { image: { fluid }, title, medium } = image;

  return ({
    image: {
      fluid,
      title,
      medium,
    },
  });
};

export default withQuery(mapDataToProps)(Index);
